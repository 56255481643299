"use client";

import * as React from "react";
import Link from "next/link";
import { MainNavItem, SiteConfig } from "@/types";
import { IconFaqtory } from "@repo/icons/custom";
import { Icons } from "@repo/icons/lucide";
import { MobileNav } from "./mobile-nav";

interface MainNavProps {
  workspaceName?: string;
  items?: MainNavItem[];
  children?: React.ReactNode;
  siteConfig: SiteConfig;
}

export function MainNav({
  items,
  children,
  workspaceName,
  siteConfig,
}: MainNavProps) {
  const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

  return (
    <div className="flex flex-1 gap-6 md:gap-10">
      <Link href="/" className="hidden items-center space-x-2 md:flex">
        <IconFaqtory className="w-20" />
        {/* <span className="hidden font-bold sm:inline-block">
          {siteConfig.name}
          {workspaceName ? ` | ${workspaceName}` : ""}
        </span> */}
      </Link>
      <button
        className="flex items-center space-x-2 md:hidden"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        {showMobileMenu ? <Icons.close /> : <Icons.logo />}
        <span className="font-bold">Menu</span>
      </button>
      {showMobileMenu && items && (
        <MobileNav items={items} siteConfig={siteConfig}>
          {children}
        </MobileNav>
      )}
      {/* <div className="flex flex-1">
        <MainNavMenu className="hidden self-end md:flex" />
      </div> */}
    </div>
  );
}
