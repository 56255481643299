import * as React from "react";
import Link from "next/link";
import { MainNavItem, SiteConfig } from "@/types";
import { useLockBody } from "@repo/hooks/use-lock-body";
import { Icons } from "@repo/icons/lucide";
import { cn } from "@repo/utils/css";

interface MobileNavProps {
  workspaceName?: string;
  items: MainNavItem[];
  children?: React.ReactNode;
  siteConfig: SiteConfig;
}

export function MobileNav({
  items,
  children,
  workspaceName,
  siteConfig,
}: MobileNavProps) {
  useLockBody();

  return (
    <div
      className={cn(
        "animate-in slide-in-from-bottom-80 fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md md:hidden"
      )}
    >
      <div className="bg-popover text-popover-foreground relative z-20 grid gap-6 rounded-md p-4 shadow-md">
        <Link href="/" className="flex items-center space-x-2">
          <Icons.logo />
          <span className="font-bold">
            {siteConfig.name}
            {workspaceName ? ` | ${workspaceName}` : ""}
          </span>
        </Link>
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {items.map((item, index) => (
            <Link
              key={index}
              href={item.disabled ? "#" : item.href}
              className={cn(
                "flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline",
                item.disabled && "cursor-not-allowed opacity-60"
              )}
            >
              {item.title}
            </Link>
          ))}
        </nav>
        {children}
      </div>
    </div>
  );
}
